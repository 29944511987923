/* eslint-disable no-unused-vars */

import {createUseStyles} from 'react-jss'
import svg from '../../config/grid-background.svg'

import colors from '../../config/colors'


const useStyles = createUseStyles({
    'grid': {
        background: 'url(' + svg + ')',
        fontSize: '16px',
        '& .rdg-header-row': {
            background: colors.gridHeaderColor,
            borderColor: '#767676',
            color: 'white',
            fontWeight: 'bold'
        },
        '& .rdg-header-sort-name': {
            fontWeight: 'bold'
        },
        '& .rdg-row': {
            height: '50px'
        },
        '& .rdg-cell': {
            color: 'black',
            border: 0,
            fontWeight: 'normal',
            borderWidth: '1px',
            borderStyle: 'dashed',
            borderColor: 'transparent',
            '&:hover': {
                borderWidth: '1px',
                borderStyle: 'dashed',
                borderColor: 'grey',
            }
        },
        '& .rdg-cell-selected': {
            background: 'antiquewhite',
            fontWeight: 'bold'
        },
        '& .rdg-cell-frozen': {
            color: colors.frozenColumnFontColor
        },
        '& .rdg-header-sort-cell': {
            color: 'white'
        },

        color: 'red'
    },
    pageHeaderInvisible: {
        height: 0,
        visibility: 'hidden',
        transitionProperty: 'height',
        transitionDuration: '0.1s',
        transitionDelay: '0.1s',
    },
    pageHeader: {
        width: '100%',
        transitionProperty: 'height',
        transitionDuration: '0.1s',
        transitionDelay: '0.1s',
        '& .title': {
            fontSize: '1.5em',
            paddingLeft: '0.5em',
            paddingRight: '0.5em',
        },
        '& .desc': {
            fontSize: '1em'
        },
        '& .right': {
            fontSize: '1em',
        }
    }
}
)

export default useStyles
