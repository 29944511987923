import React from 'react'
import Badge from 'react-bootstrap/Badge'
import formatterPropTypes from './utilities'

FormatterStatus.propTypes = formatterPropTypes

const messages = [
    'unassigned',
    'assigned',
    'won',
    'unknown',
    'lost'
]

const variants = [
    'warning',
    'info',
    'success',
    'light',
    'danger'
]

export default function FormatterStatus(props) {
    const status = props.row[props.column.key]

    const message = messages[status]
    const variant = variants[status]

    return (
        <Badge variant={variant} style={{width: '8em'}}>{message}</Badge>
    )
}
