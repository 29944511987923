import React, {useEffect, useState} from 'react'
import countries from '../../../config/countries'
import * as R from 'ramda'
import editorPropTypes from './utilities'

EditorCountry.propTypes = editorPropTypes

export default function EditorCountry(props) {
    var initCountry = props.row[props.column.key]
    var [country, setCountry] = useState(initCountry)
    useEffect(() => {
        if(country === initCountry){
            return
        }
        props.row.country = country
        props.onRowChange(props.row, true)
    })

    const addCountry = (country) => {
        return <option
            key={country.iso_code}
            value={country.iso_code}
        >
            {country.name}
        </option>
    }

    let countryOptions = R.map(addCountry, countries)

    let onChange = (thing) => {
        setCountry(thing.target.value)
    }

    return (
        <>
            <label htmlFor="cars">country:</label>
            <select name="cars" id="cars"
                onChange={onChange}
            >
                {countryOptions}
            </select>
        </>
    )
}
