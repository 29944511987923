//colors

let colors = {
    gridHeaderColor : '#4d4d4d',
    backgroundColor : 'white',
    frozenColumnFontColor : '#2985fa',
    lineColor :'#2985fa',

    menuBackground : '#2c3b4d',
    menuColor: '#d5e3f3',

    green : '#29bb52',
    blue : 'blue',
    ochre : '#c2a426',
    red : '#b91719'
}
export default colors

