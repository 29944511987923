import React from 'react'
import formatterPropTypes from './utilities'
import Badge from 'react-bootstrap/Badge'

import randomcolor from 'randomcolor'

FormatterSalesperson.propTypes = formatterPropTypes

export default function FormatterSalesperson(props) {
    const name = props.row[props.column.key]
    const firstLetter = name.charAt(0)
    const color = randomcolor({seed: name})

    return (
        <>
            <Badge pill style={{background: color}}>{firstLetter}</Badge>
            &nbsp;{name}
        </>
    )
}
