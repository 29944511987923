import * as R from 'ramda'

var column_definitions = [
    {
        key: 'id',
        name: 'ID',
        editable: true,
        sortable: true,
        _filterRenderer: 'filterrenderer.text'
    },
    {
        key: 'name',
        name: 'Name',
        _editor: 'editor.text',
        _filterRenderer: 'filterrenderer.text',
        width: 130
    },
    {
        key: 'salesperson',
        name: 'SalesPerson',
        _formatter: 'formatter.salesperson',
        _editor: 'editor.text',
        _filterRenderer: 'filterrenderer.text',
    },
    {
        key: 'sale',
        name: 'Sale',
        _formatter: 'formatter.currency',
        _editor: 'editor.currency',
        _filterRenderer: 'filterrenderer.currency',
        width: 180
    },
    {
        key: 'customer',
        name: 'Customer',
        width: 130,
        _formatter: 'formatter.customer',
        _editor: 'editor.boolean',
        _filterRenderer: 'filterrenderer.boolean',
    },
    {
        key: 'estimate',
        name: 'Estimate',
        _formatter: 'formatter.currency',
        _editor: 'editor.currency',
        // _filterRenderer: 'filterrenderer.currency',
        width: 180
    },
    {
        key: 'progress',
        name: 'Progress',
        _formatter: 'formatter.progress',
        _filterRenderer: 'filterrenderer.progress',
        _editor: 'editor.progress',
        width: 130
    },
    {
        key: 'email',
        name: 'Email',
        _editor: 'editor.text',
        _filterRenderer: 'filterrenderer.text',
    },
    {
        key: 'company',
        name: 'Company',
        _editor: 'editor.text',
        _filterRenderer: 'filterrenderer.text',
        frozen: true
    },
    {
        key: 'country',
        name: 'Country',
        _editor: 'editor.country',
        _formatter: 'formatter.country',
        _filterRenderer: 'filterrenderer.country',
        width: 130,
        resizable: true
    },
    {
        key: 'last_event',
        name: 'Last Event',
        _editor: 'editor.text',
        _formatter: 'formatter.date',
        _filterRenderer: 'filterrenderer.date',
        width: 120
    },
    {
        key: 'modified_date',
        name: 'Mod Date',
        _editor: 'editor.text',
        _formatter: 'formatter.date',
        width: 120
    },
    {
        key: 'date_added',
        name: 'Date Added',
        _editor: 'editor.text',
        _formatter: 'formatter.date',
        _filterRenderer: 'filterrenderer.date',
        width: 120
    },
    {
        key: 'last_update',
        name: 'Last Update',
        _editor: 'editor.text',
        _formatter: 'formatter.date',
        width: 120
    },
    {
        key: 'source',
        name: 'Source',
        resizable: false,
        width: 80
    },
    {
        key: 'status',
        name: 'Status',
        resizable: false,
        width: 120,
        _filterRenderer: 'filterrenderer.status',
        _formatter: 'formatter.status'
    }
]

let default_columns = [
    'company',
    'name',
    'date_added',
    'country',
    'salesperson',
    'progress',
    'status',
    'customer',
    'last_event',
]

const select_column = (column) => {
    return R.findLast(R.propEq('key', column))(column_definitions)
}

const columns = R.map(select_column, default_columns)

function hasFilterable(acc, value) {
    if (value._filterRenderer) {
        acc[value.key] = ''
    }
    return acc
}

export const clearFilterDesc = R.reduce(hasFilterable, {}, column_definitions)

export default columns
