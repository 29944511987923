import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faCircle} from '@fortawesome/free-solid-svg-icons'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import progressText from '../../../config/progressConfig'
import formatterPropTypes from './utilities'


FormatterProgress.propTypes = formatterPropTypes

export default function FormatterProgress(props) {
    const progress = props.row[props.column.key]
    var icons = []

    if (progress === 5) {
        icons = (
            <FontAwesomeIcon key={progress} icon={faCheck} size="lg" color="green" style={{marginLeft: '0.2em'}}/>
        )
    } else {
        for (let i = 0; i < 5; i++) {
            if (i < progress) {
                icons.push(
                    <FontAwesomeIcon key={i} icon={faCircle} size="xs" color="green" style={{marginLeft: '0.2em'}}/>
                )
            } else {
                icons.push(
                    <FontAwesomeIcon key={i} icon={faCircle} size="xs" color="lightGreen"
                        style={{marginLeft: '0.2em'}}/>
                )
            }
        }
    }

    const progressName = progressText(progress)

    return (
        <OverlayTrigger delay={1000} overlay={<Tooltip id="tooltip-disabled">{progressName}</Tooltip>}>
            <>
                {icons}
            </>
        </OverlayTrigger>
    )
}
