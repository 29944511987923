let progressConfig = [
    'Received',
    'Assigned',
    'Contacted',
    'Quoted',
    'Followed Up'
]

function progressText(offset) {
    if (offset > progressConfig) {
        return 'unknown progress value:' + offset
    }
    return progressConfig[offset - 1]
}

export default progressText
