import React, {useCallback, useState} from 'react'
import ReactDataGrid from 'react-data-grid'
import columns from '../../config/customerColumns'
import clearFilterDesc from '../../config/customerColumns'
import * as R from 'ramda'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import createRows from '../../dummy-data/generate-customers'

import * as u from './utilities'
import useStyles from './styles'
import {faEdit, faFilter, faPlus, faSave, faSearch, faTrash} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Row from 'react-bootstrap/Row'
import Dropdown from 'react-bootstrap/Dropdown'
import Col from 'react-bootstrap/Col'

var calcCustomers = R.memoizeWith(R.identity, createRows)
var customers = calcCustomers(10000)

const Leads = () => {
    const numCustomers = customers.length
    const [gridState, setGridState] = useState(customers)
    const [numCustomersShown, setNumCustomersShown] = useState(numCustomers)
    const [[sortColumn, sortDirection], setSort] = useState(['id', 'NONE'])
    const [enableFilterRow, setEnableFilterRow] = useState(false)
    const [scrolling, setScrolling] = useState({
        pos: 0,
        down: false
    })

    var fulfilledColumns = R.map(u.fulfill, columns)

    const onRowsChange = (props) => {
        setGridState(props)
    }

    const handleSort = useCallback((columnKey, direction) => {
        setSort([columnKey, direction])
    }, [])

    const [filters, setFilters] = useState(clearFilterDesc)

    function clearFilters() {
        setFilters(clearFilterDesc)
    }

    function toggleFilters() {
        setEnableFilterRow(!enableFilterRow)
    }

    function onScroll(evt) {
        console.log('scrolling = ' + JSON.stringify(scrolling, null, 4))

        const isDown = scrolling.pos < evt.target.scrollTop
        // if(isDown === scrolling.down){
        //     return
        // }

        let newVar = {
            pos:evt.target.scrollTop,
            down: isDown
        }

        console.log('new scroll = ' + JSON.stringify(newVar, null, 4))

        setScrolling(newVar)
    }

    const filteredRows = u.filterRows(gridState, filters, setNumCustomersShown)

    const sortedRows = ((sortColumn, sortDirection, rows) => {
        if(sortDirection === 'ASC'){
            return R.sortWith([R.ascend(R.prop(sortColumn))])(rows)
        }else if(sortDirection === 'DESC'){
            return R.sortWith([R.descend(R.prop(sortColumn))])(rows)
        } else {
            return rows
        }
    })(sortColumn, sortDirection, filteredRows)

    const filterIndicator = (numCustomersShown === numCustomers) ?
        ('Showing all ' + numCustomers + ' customers') :
        ('Showing ' + numCustomersShown + ' out of ' + numCustomers + ' customers')

    const classes = useStyles()

    return (
        <Container fluid="lg" style={{paddingTop:'0.5em', margin:0, maxWidth:'100%'}}>
            <Row className={scrolling.down ? classes.pageHeaderInvisible : classes.pageHeader}>
                <Col md="auto" className="title">Prospect List</Col>
                <Col md="auto" className={'desc'}>
                    {filterIndicator}
                </Col>
                <Col>
                    <Button size="sm" type="button" onClick={toggleFilters}>
                        <FontAwesomeIcon icon={faFilter}/>
                        &nbsp;Toggle Filters
                    </Button>
                </Col>
                <Col>
                    <Button size="sm" type="button" onClick={clearFilters}>
                        <FontAwesomeIcon icon={faTrash}/>
                        &nbsp;Clear Filters
                    </Button>
                </Col>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
                            <FontAwesomeIcon icon={faSearch}/>
                            &nbsp;Saved Searches
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>high rollers</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">looking good</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-3">
                                <FontAwesomeIcon icon={faSave}/>
                                &nbsp;save</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                                <FontAwesomeIcon icon={faSave}/>
                                &nbsp;save as...</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                                <FontAwesomeIcon icon={faEdit}/>
                                &nbsp;edit searches</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col>
                    <Button size="sm" type="button" variant='outline-primary'>
                        <FontAwesomeIcon icon={faPlus}/>
                        &nbsp;Add Prospect
                    </Button>
                </Col>
            </Row>
            <ReactDataGrid
                onScroll={onScroll}
                className={classes.grid}
                style={{height: '50em'}}
                headerFiltersHeight={30}
                columns={fulfilledColumns}
                rows={sortedRows}
                onRowsChange={onRowsChange}
                enableFilterRow={enableFilterRow}
                filters={filters}
                onFiltersChange={setFilters}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
                cellNavigationMode="CHANGE_ROW"
            />
        </Container>
    )
}

export default Leads
