import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSquare} from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import progressText from '../../../config/progressConfig'
import editorPropTypes from './utilities'


EditorProgress.propTypes = editorPropTypes

export default function EditorProgress(props) {
    const initProgress = props.row[props.column.key]
    let [progress, setProgress] = useState(initProgress)
    useEffect(() => {
        if (progress === initProgress) {
            return
        }
        props.row.progress = progress
        props.onRowChange(props.row, true)
    })
    let icons = []

    const onclick = (i) => {
        return (
            () => {
                setProgress(i)
            }
        )
    }

    for (let i = 1; i <= 5; i++) {
        const text = progressText(i)
        if (i < progress) {
            icons.push(
                <OverlayTrigger key={i} overlay={<Tooltip id="tooltip-disabled">{text}</Tooltip>}>
                    <FontAwesomeIcon icon={faSquare} size="xs" color="green" style={{marginLeft: '0.2em'}}
                        onClick={onclick(i)}/>
                </OverlayTrigger>
            )
        } else {
            icons.push(
                <OverlayTrigger key={i} overlay={<Tooltip id="tooltip-disabled">{text}</Tooltip>}>
                    <FontAwesomeIcon icon={faSquare} size="xs" color="lightGreen" style={{marginLeft: '0.2em'}}
                        onClick={onclick(i)}/>
                </OverlayTrigger>
            )
        }
    }

    return (

        <div style={{background: 'pink'}}>
            &nbsp; {icons}
        </div>
    )
}
