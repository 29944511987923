import Nav from 'react-bootstrap/Nav'
import {LinkContainer} from 'react-router-bootstrap'
import {createUseStyles} from 'react-jss'
import colors from '../../config/colors'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBell,
    faChevronCircleLeft,
    faChevronCircleRight,
    faCog,
    faEnvelopeOpen,
    faLink,
    faLock,
    faTable,
    faTasks,
    faUserFriends,
} from '@fortawesome/free-solid-svg-icons'

import React, {useState} from 'react'

const useStyles = createUseStyles({
    'menuTextShow': {
        visibility: 'visible',
        transitionProperty: 'visibility',
        transitionDelay: '0.3s',
        transitionDuration: '0.1s'
    },
    'menuTextHidden': {
        visibility: 'hidden',
    },
    'menu': {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        background: colors.menuBackground,
        fontFamily: 'open sans, helvetica',
        fontSize: '14px',
        width: '12em',
        '.nav-link': {
            overflow: 'hidden',
        },
        '& a': {
            height: '2.5em',
            color: colors.menuColor,
            '&:hover': {
                background: 'orange',
                transitionProperty: 'background',
                transitionDelay: '0.1s',
                transitionDuration: '0.4s'
            }
        },
    },
    adjustWidth: {
        position: 'relative',
        right: '-0.5em',
        float:'right',
        color: 'white',
        height: '2em'
    }
}
)

const AdjustWidth = (props) => {
    const classes = useStyles()

    // eslint-disable-next-line react/prop-types
    const arrow = props.wide ? faChevronCircleLeft : faChevronCircleRight

    return (
        // eslint-disable-next-line react/prop-types
        <div onClick={props.onClick} className={classes.adjustWidth}>
            <FontAwesomeIcon icon={arrow} size="2x"/>
        </div>
    )
}

const MenuItem = (props) => {
    const classes = useStyles()
    // eslint-disable-next-line react/prop-types
    const text = (props.wide ? ( <span className={classes.menuTextShow}>{props.text}</span>) : ( <span className={classes.menuTextHidden}>{props.text}</span>))
    return (
        // eslint-disable-next-line react/prop-types
        <LinkContainer to={props.to}>
            <Nav.Link>
                {/* eslint-disable-next-line react/prop-types */}
                <FontAwesomeIcon icon={props.icon} size="1x" style={{width: '2em', float: 'left'}}/>
                {/* eslint-disable-next-line react/prop-types */}
                &nbsp;{text}
            </Nav.Link>
        </LinkContainer>
    )
}

export default function Navigation() {
    const [wide, setWide] = useState(true)
    const classes = useStyles()

    const onClick = () => {
        setWide(!wide)
    }

    const widthStyle = wide ? {
        width: '15em',
        transitionProperty: 'all',
        transitionDuration: '0.1s',
        transitionDelay: '0.1s',
    } : {
        width: '4em',
        transitionProperty: 'all',
        transitionDuration: '0.1s',
        transitionDelay: '0.1s',
    }

    return (
        <div className={classes.menu} style={widthStyle}>
            <div>
                <AdjustWidth onClick={onClick} wide={wide}/>
                <br/>
                <br/>
                <MenuItem
                    to="/"
                    text="Customers"
                    icon={faUserFriends}
                    wide={wide}
                />

                <MenuItem
                    to="/notifications"
                    text="Notifications"
                    icon={faBell}
                    wide={wide}
                />

                <MenuItem
                    to="/tasks"
                    text="Tasks"
                    icon={faTasks}
                    wide={wide}
                />

                <MenuItem
                    to="/reports"
                    text="Reports"
                    icon={faTable}
                    wide={wide}
                />

                <MenuItem
                    to="/mail"
                    text="Mail"
                    icon={faEnvelopeOpen}
                    wide={wide}
                />
            </div>
            <div>

                <MenuItem
                    to="/link-building"
                    text="Link Building"
                    icon={faLink}
                    wide={wide}
                />

                <MenuItem
                    to="/settings"
                    text="Settings"
                    icon={faCog}
                    wide={wide}
                />

                <MenuItem
                    to="/log-out"
                    text="Log Out"
                    icon={faLock}
                    wide={wide}
                />
                <br/>
                <br/>
            </div>
        </div>
    )
}
