import React from 'react'
import formatterPropTypes from './utilities'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'

FormatterBoolean.propTypes = formatterPropTypes

export default function FormatterBoolean(props) {
    const bool = props.row[props.column.key]

    var icon = bool ? faCheck : faTimes
    var color = bool ? 'green' : 'red'

    return (
        <FontAwesomeIcon icon={icon} size="lg" color={color} style={{marginLeft: '0.2em'}}/>
    )
}
