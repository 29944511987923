import faker from 'faker'
import moment from 'moment'

export default function createRows(size) {
    const rows = []

    let fakePastDate = function () {
        let inp = faker.date.past()
        return moment(inp).format()
    }

    let fakeStatus = function (statuses) {
        let p = faker.random.number(statuses.length - 1)
        return statuses[p]
    }
    for (let i = 0; i < size; i++) {
        rows.push({
            id: i + 1,
            company: faker.company.companyName(),
            name: faker.name.firstName(),
            salesperson: faker.name.firstName(),
            email: faker.internet.exampleEmail(),
            date_added: fakePastDate(),
            last_update: fakePastDate(),
            last_event: fakePastDate(),
            customer: faker.random.boolean(),
            country: faker.address.countryCode(),
            modified_date: fakePastDate(),
            source: faker.random.number(4),
            status: fakeStatus([0, 1, 2, 4]),
            estimate: faker.random.number(100) * 1000,
            sale: faker.random.number(100) * 1000,
            progress: faker.random.number(4) + 1
        })
    }

    return rows
}
