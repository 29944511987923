import {TextEditor} from 'react-data-grid'
import EditorCountry from './Editors/EditorCountry'
import EditorProgress from './Editors/EditorProgress'
import EditorBoolean from './Editors/EditorBoolean'
import EditorCurrency from './Editors/EditorCurrency'
import FormatterCountry from './Formatters/FormatterCountry'
import FormatterCurrency from './Formatters/FormatterCurrency'
import FormatterDate from './Formatters/FormatterDate'
import FormatterStatus from './Formatters/FormatterStatus'
import FormatterProgress from './Formatters/FormatterProgress'
import FormatterSalesperson from './Formatters/FormatterSalesperson'
import FormatterBoolean from './Formatters/FormatterBoolean'
import * as renderers from './FilterRenderers/FilterRenderers'
import * as R from 'ramda'
import FormatterCustomer from './Formatters/FormatterCustomer'
import {useMemo} from 'react'
import countries from '../../config/countries'

const columnDefaults = {
    editable: true,
    sortable: true,
    filterable: true,
    resizable: true,
    width: 200,
}

var fulfill = (o) => {
    if (o._editor === 'editor.text') {
        o.editor = TextEditor
        o._editor = null
    }
    if (o._editor === 'editor.country') {
        o.editor = EditorCountry
        o._editor = null
    }
    if (o._editor === 'editor.progress') {
        o.editor = EditorProgress
        o._editor = null
    }
    if (o._editor === 'editor.boolean') {
        o.editor = EditorBoolean
        o._editor = null
    }
    if (o._editor === 'editor.currency') {
        o.editor = EditorCurrency
        o._editor = null
    }
    if (o._formatter === 'formatter.country') {
        o.formatter = FormatterCountry
        o._formatter = null
    }
    if (o._formatter === 'formatter.currency') {
        o.formatter = FormatterCurrency
        o._formatter = null
    }
    if (o._formatter === 'formatter.salesperson') {
        o.formatter = FormatterSalesperson
        o._formatter = null
    }
    if (o._formatter === 'formatter.date') {
        o.formatter = FormatterDate
        o._formatter = null
    }
    if (o._formatter === 'formatter.status') {
        o.formatter = FormatterStatus
        o._formatter = null
    }
    if (o._formatter === 'formatter.progress') {
        o.formatter = FormatterProgress
        o._formatter = null
    }
    if (o._formatter === 'formatter.boolean') {
        o.formatter = FormatterBoolean
        o._formatter = null
    }
    if (o._formatter === 'formatter.customer') {
        o.formatter = FormatterCustomer
        o._formatter = null
    }
    if (o._filterRenderer === 'filterrenderer.text') {
        o.filterRenderer = renderers.FilterRendererText
        o._filterRenderer = null
    }
    if (o._filterRenderer === 'filterrenderer.country') {
        o.filterRenderer = renderers.FilterRendererCountry
        o._filterRenderer = null
    }
    if (o._filterRenderer === 'filterrenderer.currency') {
        o.filterRenderer = renderers.FilterRendererCurrency
        o._filterRenderer = null
    }
    if (o._filterRenderer === 'filterrenderer.boolean') {
        o.filterRenderer = renderers.FilterRendererBoolean
        o._filterRenderer = null
    }
    if (o._filterRenderer === 'filterrenderer.progress') {
        o.filterRenderer = renderers.FilterRendererProgress
        o._filterRenderer = null
    }
    if (o._filterRenderer === 'filterrenderer.date') {
        o.filterRenderer = renderers.FilterRendererDate
        o._filterRenderer = null
    }
    if (o._filterRenderer === 'filterrenderer.status') {
        o.filterRenderer = renderers.FilterRendererStatus
        o._filterRenderer = null
    }
    if (o._filterRenderer) {
        // eslint-disable-next-line no-console
        console.error('filterRenderer not implemented = ' + o._filterRenderer)
    }
    if (o._formatter) {
        // eslint-disable-next-line no-console
        console.error('formatter not implemented = ' + o._formatter)
    }
    if (o._editor) {
        // eslint-disable-next-line no-console
        console.error('editor not implemented = ' + o._editor)
    }
    o = R.mergeRight(columnDefaults, o)
    return o
}

let countryCode = R.memoizeWith(R.identity, code => R.find(R.propEq('iso_code', code))(countries))

function checkCountry(filter, code) {
    let country = countryCode(code)
    let name = country ? country.name : code

    name = name.toUpperCase()
    filter = filter.toUpperCase()
    var partFilters = R.split(' ', filter)
    for(let part of partFilters){
        if(name.includes(part)){
            return true
        }
    }
    return false
}

let filterRows = function (gridState, filters, setNumCustomersShown) {
    return useMemo(() => {
        let ret = gridState.filter(r => {
            return (
                (filters.company ? (r.company.toUpperCase() + '').includes(filters.company.toUpperCase()) : true)
                && (filters.name ? (r.name.toUpperCase() + '').includes(filters.name.toUpperCase()) : true)
                && (filters.salesperson ? (r.salesperson.toUpperCase() + '').includes(filters.salesperson.toUpperCase()) : true)
                && (filters.email ? (r.email.toUpperCase() + '').includes(filters.email.toUpperCase()) : true)
                && (filters.customer ? (r.email.toUpperCase() + '').includes(filters.email.toUpperCase()) : true)
                && (filters.country ? checkCountry(filters.country, r.country) : true)
            )
        })
        setNumCustomersShown(ret.length)
        return ret
    }, [gridState, filters, setNumCustomersShown])
}


export {
    fulfill,
    filterRows
}
