import React from 'react'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'

const rendererPropTypes = {
    column: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func
}

const defaultStyle = {
    fontStyle: 'italic',
    fontWeight: 'lighter',
    color: 'pink'
}

const FilterRendererText = (p) => {
    return (
        <div>
            <input style={{height: '2em'}}
                value={p.value}
                onChange={e => {
                    p.onChange(e.target.value)
                }}
            />
        </div>
    )
}

const FilterRendererCountry = (p) => {
    return (
        <div>
            <input style={{height:'2em'}}
                value={p.value}
                placeholder="use spaces"
                onChange={e => {
                    p.onChange(e.target.value)
                }}
            />
        </div>
    )
}

const FilterRendererCurrency = () => {
    // const currentFilter = p.value
    const popover = (
        <Popover id="popover-basic"
            fade={true}
        >
            <Popover.Title as="h3">Select a <b>sale</b> range in £</Popover.Title>
            <Popover.Content>
                <InputGroup size="sm" className="mb-3">
                    Doesnt do anything yet
                </InputGroup>
                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-sm">from</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" defaultValue={2000}/>
                    <br/>
                </InputGroup>
                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-sm">to</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" defaultValue={10000}/>
                    <br/>
                </InputGroup>
                <Button variant="success">go, you magnificent bastard</Button>
                <Button variant="danger">cancel</Button>
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger trigger={['click']} placement="right" overlay={popover}>
            <Button variant="success">£2k to £10k</Button>
        </OverlayTrigger>
    )
}

function FilterRendererBoolean() {
    return (
        <div  style={defaultStyle}>
            {'boolean fltr NI'}
        </div>
    )
}

function FilterRendererStatus() {
    return (
        <div  style={defaultStyle}>
            {'status fltr NI'}
        </div>
    )
}

function FilterRendererProgress() {
    return (
        <div  style={defaultStyle}>
            {'boolean fltr NI'}
        </div>
    )
}

function FilterRendererDate() {
    return (
        <div style={defaultStyle}>
            {'date fltr NI'}
        </div>
    )
}

FilterRendererCountry.propTypes = rendererPropTypes
FilterRendererText.propTypes = rendererPropTypes
FilterRendererCurrency.propTypes = rendererPropTypes
FilterRendererProgress.propTypes = rendererPropTypes
FilterRendererDate.propTypes = rendererPropTypes
FilterRendererBoolean.propTypes = rendererPropTypes
FilterRendererStatus.propTypes = rendererPropTypes

export {
    FilterRendererCountry,
    FilterRendererText,
    FilterRendererCurrency,
    FilterRendererProgress,
    FilterRendererDate,
    FilterRendererBoolean,
    FilterRendererStatus
}
