import React from 'react'
import formatterPropTypes from './utilities'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

FormatterCustomer.propTypes = formatterPropTypes

export default function FormatterCustomer(props) {
    const bool = props.row[props.column.key]

    if(bool){
        return (<FontAwesomeIcon icon={faCheck} size="lg" color={'green'} style={{marginLeft: '0.2em'}}/>)
    }else{
        return <></>
    }
}
