import React, {useState} from 'react'
import moment from 'moment'
import Badge from 'react-bootstrap/Badge'
import formatterPropTypes from './utilities'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

FormatterDate.propTypes = formatterPropTypes

export default function FormatterDate(props) {
    let [tooltip, setTooltip] = useState(false)
    const date = props.row[props.column.key]
    const m = moment(date)
    let fromNow = m.fromNow()
    const nicer = m.format('YYYY/MM/DD hh:mm')

    let variant = 'success'
    if (fromNow.includes('week')) {
        variant = 'primary'
        fromNow = fromNow.replace('week', 'wk')
    } else if (fromNow.includes('month')) {
        variant = 'danger'
        fromNow = fromNow.replace('month', 'mnth')
    } else if (fromNow.includes('year')) {
        variant = 'warning'
        fromNow = fromNow.replace('year', 'yr')
    }

    fromNow = fromNow.replace(' ago', '')

    const onHover = () => {
        setTooltip(true)
    }

    let badge = (
        <Badge onMouseEnter={onHover} variant={variant} style={{width: '7em', fontWeight: 'normal'}}>
            {fromNow}
        </Badge>
    )

    if(tooltip){
        const newBadge = (
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{nicer}</Tooltip>}>
                {badge}
            </OverlayTrigger>
        )

        badge = newBadge
    }

    return (
        <>{badge}</>
    )
}
