import React from 'react'
import formatterPropTypes from './utilities'

FormatterCurrency.propTypes = formatterPropTypes

export default function FormatterCurrency(props) {
    const colname = props.column.key
    const val = props.row[colname]
    const text = '£' + val.toLocaleString('en-UK')

    return (
        <div style={{textAlign: 'right'}}>
            {text}
        </div>
    )
}
