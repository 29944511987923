import React from 'react'
import countries from '../../../config/countries'
import * as R from 'ramda'
import formatterPropTypes from './utilities'

FormatterCountry.propTypes = formatterPropTypes

export default function FormatterCountry(props) {
    const code = props.row.country
    const country = R.find(R.propEq('iso_code', code))(countries)
    const name = country ? country.name : code

    return (
        <>
            {name}
        </>

    )
}
