import React, {useState} from 'react'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import editorPropTypes from './utilities'

EditorCurrency.propTypes = editorPropTypes

export default function EditorCurrency(props) {
    let colname = props.column.key
    let [val, setVal] = useState(props.row[colname])

    const change = (p) => {
        let s = p.target.value
        if (s === '') {
            setVal(0)
            p.target.value = 0
            return
        }

        let number = parseInt(s)
        if (!Number.isInteger(number)) {
            p.target.value = val
        } else {
            p.target.value = number
            setVal(number)
        }
    }

    const complete = () => {
        props.row[colname] = parseInt(val, 10)
        props.onRowChange(props.row, true)
    }

    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                aria-label="Amount (to the nearest dollar)"
                defaultValue={val}
                onChange={change}
                autofocus={true}
                onBlur={complete}
                onKeyDown={(evt) => {
                    if (evt.keyCode === 13) {
                        complete()
                    }
                }}
            />
            <InputGroup.Append>
                <InputGroup.Text>.00</InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    )
}
