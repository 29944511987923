import React from 'react'
import editorPropTypes from './utilities'
import Form from 'react-bootstrap/Form'


EditorBoolean.propTypes = editorPropTypes

export default function EditorBoolean(props) {
    const bool = props.row[props.column.key]
    // props.row[props.column.key] = false
    // props.onRowChange(props.row, true)

    return (
        <Form.Check
            type="switch"
            id="custom-switch"
            label="is a customer"
            checked={bool}
            onClick={(evt)=>{
                props.row[props.column.key] = evt.target.checked
                props.onRowChange(props.row, true)
            }}
        />
    )
}
