import './App.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import Error from './components/Error'
import Navigation from './components/Navigation'

import {Route, Switch} from 'react-router-dom'
import Leads from './components/Leads'
import Tasks from './components/Tasks'
import Notifications from './components/Notifications'
import Reports from './components/Reports'
import Mail from './components/Mail'
import LinkBuilding from './components/LinkBuilding'
import Settings from './components/Settings'
import LogOut from './components/LogOut'


function App() {
    return (
        <div style={{display:'inline-flex', width:'100%'}}>
            <Navigation/>
            <Switch>
                <Route path="/" component={Leads} exact />
                <Route path="/notifications" component={Notifications} />
                <Route path="/tasks" component={Tasks} />
                <Route path="/reports" component={Reports} />
                <Route path="/mail" component={Mail} />
                <Route path="/link-building" component={LinkBuilding} />
                <Route path="/settings" component={Settings} />
                <Route path="/log-out" component={LogOut} />
                <Route component={Error} />
            </Switch>
        </div>
    )
}

export default App
